// import { MainWatcherStyles } from './MainWatcherStyles'
import React, { FC, useCallback, useEffect, memo } from 'react'
import { MainBaseWatcher } from 'kat/lib/com/watcher/MainBaseWatcher'
import { UpdateNativeAppWatcher } from 'kat/lib/com/watcher/UpdateNativeAppWatcher'
import { useSelector } from 'react-redux'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { useDispatch } from 'react-redux'
import { SetCurrentWorkspaceIdAction } from 'kat/lib/actionCreators/set/SetCurrentWorkspaceIdAction'
// import { InvitesWatcherConnected } from 'com/watcher/InvitesWatcher'
import { EnableNotificationWatcher } from 'kat/lib/com/watcher/EnableNotificationWatcher/EnableNotificationWatcher'
import { ErrorWatcher } from 'kat/lib/com/watcher/ErrorWatcher'
import { HistoryAction } from 'kat/lib/actionCreators/types/HistoryAction'
import { appRoutes } from 'functions/routes/appRoutes'
import { CanGoBackWatcher } from './CanGoBackWatcher'
import { AuthChangeWatcher } from 'kat/lib/com/watcher/AuthChangeWatcher'
import { LoginRedirectWatcher } from './LoginRedirectWatcher'
import { createLocationAction } from 'state/actions/createLocationAction'
import { ServiceWorkerMessageWatcherWrapper } from 'com/wrapper/ServiceWorkerMessageWatcherWrapper'
import { getIsSideAppRoute } from 'state/getters/getIsSideAppRoute'
import { StylesWatcher } from 'kat/lib/com/watcher/StylesWatcher'
import { setAppStyles } from 'styles/appStyles'
import { ScrollPositionWatcher } from './ScrollPositionWatcher'

const description = 'MainWatcher'

const setMixPanelUserId = (userId: string) => {
  // Set this to a unique identifier for the user performing the event.
  window.mixpanel?.identify?.(userId)
}

export const MainWatcher: FC = memo(() => {
  const dispatch = useDispatch()

  const workspaceId = useSelector((state: StateReducers) => {
    return (
      state.currentLocation?.query?.workspaceId ||
      state.currentLocation?.query?.wi
    )
  })

  const currentUserId = useSelector((state: StateReducers) => {
    return state.currentUserId
  })

  const workspaceHomeRoute = useSelector((state: StateReducers) => {
    return state.currentLocation?.isRoutes?.workspaceHomeRoute
  })

  useEffect(() => {
    if (currentUserId) {
      // hack to run this over in case user signs out and back in
      // user signs out, reducer gets wiped, user signs back in, and reducer never gets restored
      // issue was happening on player screen
      // TODO: better solutions
    }
    dispatch<SetCurrentWorkspaceIdAction>({
      type: 'SET_CURRENT_WORKSPACE_ID_ACTION',
      description,
      workspaceId: workspaceId || null,
    })

    return () => {
      dispatch<SetCurrentWorkspaceIdAction>({
        type: 'SET_CURRENT_WORKSPACE_ID_ACTION',
        description,
        workspaceId: null,
      })
    }
  }, [workspaceId, dispatch, currentUserId])

  const isPreLoginRoute = useSelector((state: StateReducers) => {
    return state.currentLocation?.isRoutes?.isPreLoginRoute
  })

  const isSideAppRoute = useSelector((state: StateReducers) => {
    return getIsSideAppRoute({ state })
  })

  const gameRoute = useSelector((state: StateReducers) => {
    return state.currentLocation?.isRoutes?.gameRoute
  })

  const rootRoute = useSelector((state: StateReducers) => {
    return state.currentLocation?.isRoutes?.rootRoute
  })

  const handlePermissionDenied = useCallback(() => {
    if (!isPreLoginRoute) {
      dispatch<HistoryAction>(
        createLocationAction({
          description,
          method: 'replace',
          pathname: appRoutes.workspacesRoute.pathname,
        })
      )
    }
  }, [isPreLoginRoute, dispatch])

  return (
    <>
      <ScrollPositionWatcher />
      <StylesWatcher setAppStyles={setAppStyles} />
      {(rootRoute || !isSideAppRoute) && <LoginRedirectWatcher />}

      <AuthChangeWatcher onSignIn={setMixPanelUserId} />
      <ErrorWatcher handlePermissionDenied={handlePermissionDenied} />

      {(workspaceHomeRoute || gameRoute) && <EnableNotificationWatcher />}

      {!isSideAppRoute && !rootRoute && (
        <>
          <ServiceWorkerMessageWatcherWrapper />
          <UpdateNativeAppWatcher />
          <CanGoBackWatcher />
          <MainBaseWatcher
            homePathname={appRoutes.welcomeStepRoute.pathname}
            workspaceId={workspaceId || ''}
            useWorkspaceWatcher={true}
          />
        </>
      )}
    </>
  )
})
