import {
  analyticsEventBase,
  AnalyticsEventBase,
  extendsAnalyticsEventBase,
} from 'kat/lib/sdks/firebase/analyticsEventBase'

type AnalyticsEvent = AnalyticsEventBase<
  | 'Get started: Center'
  | 'Get started: Bottom'
  | 'Begin Onboarding'
  | 'player: initial load'
  | 'player: sign in anonymously start'
  | 'player: sign in anonymously success'
  | 'player: create card start'
  | 'player: game full'
  | 'player: create card success'
  | 'player: retry max reload'
  | 'player: create card error'
  | 'player: get claim'
  | 'player: get claim error'
  | 'player: submit CardPlayerNameForm'
  | 'player: select emoji'
  | 'player: submit button'
  | 'Go back'
>

const reportImediatelyEvents: AnalyticsEvent[] = ['player: retry max reload']

interface Props {
  event: AnalyticsEvent
  params?: {
    currentUserId?: string | null | undefined // TODO: wait to see if we need this, because we're using CurrentUserIdWatcher now
    templateType?: string
  }
}

extendsAnalyticsEventBase(({ event, eventParams }) => {
  const eventAs = event as AnalyticsEvent
  if (reportImediatelyEvents.includes(eventAs)) {
    window.mixpanel?.track?.(event as string, eventParams, {
      send_immediately: true,
    })
  } else {
    window.mixpanel?.track?.(event as string, eventParams)
  }
})

export const logAnalyticsEvent = ({ event, params }: Props) => {
  analyticsEventBase({ event, eventParams: params })
}
