import { StateReducers } from 'state/reducers/types/StateReducers'

interface Props {
  state: StateReducers
}

export const getIsSideAppRoute = ({ state }: Props): boolean => {
  return (
    state.currentLocation?.isRoutes?.playGameRoute ||
    state.currentLocation?.isRoutes?.tinyRoute ||
    state.currentLocation?.isRoutes?.highlightsRoute ||
    state.currentLocation?.isRoutes?.useLayout === 'host-guest' ||
    false
  )
}
