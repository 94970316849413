import { StateReducers } from 'state/reducers/types/StateReducers'
import { appRoutes } from 'functions/routes/appRoutes'
import { AppRoute } from 'kat/lib/routes/types/AppRoute'
import { UrlQuery } from '../../functions/routes/types/UrlQuery'
import { LayoutTypesExtra } from 'functions/routes/types/LayoutTypesExtra'

interface Props {
  currentLocation: StateReducers['currentLocation']
}

interface Return {
  route?: AppRoute<LayoutTypesExtra>
  query?: UrlQuery
  withCurrentQuery?: boolean
}

export const getBackLocation = ({ currentLocation }: Props): Return => {
  const isRoutes = currentLocation && currentLocation.isRoutes
  const workspaceId =
    currentLocation?.query?.workspaceId || currentLocation?.query?.wi || ''
  const gameId =
    currentLocation?.query?.gameId || currentLocation?.query?.gi || ''
  const workspaceSettingsRoute = isRoutes?.workspaceSettingsRoute
  const accountSettingsRoute = isRoutes?.accountSettingsRoute
  const membersRoute = isRoutes?.membersRoute
  const updateDisplayNameRoute = isRoutes?.updateDisplayNameRoute
  const updatePasswordRoute = isRoutes?.updatePasswordRoute
  const workspaceFilesRoute = isRoutes?.workspaceFilesRoute
  const notificationSettingsRoute = isRoutes?.notificationSettingsRoute
  const upgradeWorkspaceRoute = isRoutes?.upgradeWorkspaceRoute
  const welcomeToProRoute = isRoutes?.welcomeToProRoute
  const workspaceSubscriptionRoute = isRoutes?.workspaceSubscriptionRoute
  const proCanceledRoute = isRoutes?.proCanceledRoute
  const updateAvatarRoute = isRoutes?.updateAvatarRoute
  const deleteWorkspaceRoute = isRoutes?.deleteWorkspaceRoute
  const reportRoute = isRoutes?.reportRoute
  const updateWorkspaceNameRoute = isRoutes?.updateWorkspaceNameRoute
  const paymentFailedRoute = isRoutes?.paymentFailedRoute
  const archiveRoute = isRoutes?.archiveRoute
  const createUserRoute = isRoutes?.createUserRoute
  const resetPasswordRoute = isRoutes?.resetPasswordRoute
  const updateWorkspaceLogoRoute = isRoutes?.updateWorkspaceLogoRoute
  const memberDetailRoute = isRoutes?.memberDetailRoute
  const inviteDetailRoute = isRoutes?.inviteDetailRoute
  const emailLoginRoute = isRoutes?.emailLoginRoute
  const deleteUserAccountRoute = isRoutes?.deleteUserAccountRoute
  const darkModeRoute = isRoutes?.darkModeRoute
  const leaveWorkspaceRoute = isRoutes?.leaveWorkspaceRoute
  const cardRoute = isRoutes?.cardRoute
  const hostGuestCardRoute = isRoutes?.hostGuestCardRoute

  if (proCanceledRoute || paymentFailedRoute) {
    return {
      route: appRoutes.postLoginHomeRoute,
      query: { workspaceId },
    }
  }

  if (cardRoute) {
    return {
      route: appRoutes.cardsRoute,
      query: { workspaceId, gameId },
    }
  }

  if (hostGuestCardRoute) {
    return {
      route: appRoutes.hostGuestCardsRoute,
      query: {
        ...currentLocation?.query,
      },
    }
  }

  if (accountSettingsRoute || inviteDetailRoute || memberDetailRoute) {
    return {
      route: appRoutes.membersRoute,
      query: { workspaceId },
    }
  }

  if (archiveRoute) {
    return {
      route: appRoutes.foldersRoute,
      query: { workspaceId },
      withCurrentQuery: false,
    }
  }

  if (
    updateDisplayNameRoute ||
    updatePasswordRoute ||
    notificationSettingsRoute ||
    updateAvatarRoute ||
    deleteUserAccountRoute ||
    darkModeRoute
  ) {
    return {
      route: appRoutes.accountSettingsRoute,
      query: { workspaceId },
    }
  }

  if (
    membersRoute ||
    workspaceFilesRoute ||
    upgradeWorkspaceRoute ||
    welcomeToProRoute ||
    workspaceSubscriptionRoute ||
    deleteWorkspaceRoute ||
    reportRoute ||
    updateWorkspaceNameRoute ||
    updateWorkspaceLogoRoute ||
    leaveWorkspaceRoute
  ) {
    return {
      route: appRoutes.workspaceSettingsRoute,
      query: { workspaceId },
    }
  }

  if (workspaceSettingsRoute) {
    return {
      route: appRoutes.workspacesRoute,
      query: { workspaceId },
    }
  }

  if (emailLoginRoute) {
    return {
      route: appRoutes.loginRoute,
    }
  }

  if (createUserRoute || resetPasswordRoute) {
    return {
      route: appRoutes.emailLoginRoute,
    }
  }

  return {}
}
